import axios from 'axios';
import { Poppins, Quicksand } from 'next/font/google';
import React, { useEffect, useState } from 'react'
const extrapopins =Poppins({weight:"500", subsets:['latin']});
const quickSand =Quicksand({weight:"400", subsets:['latin']});
const popins =Poppins({weight:"400", subsets:['latin']});
export default function EventForm({event}) {

    const [formData, setFormData] = useState({
      event: '',
      name: '',
      email: '',
      mobileNumber: '',
    });
  
  useEffect(()=>{
    setFormData({...formData,event:event});
  },[event])
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  const handleSubmit = async(e) =>{
    e.preventDefault();

    try {
   const response = await axios.post(`${process.env.url}/api/sendgrid`,formData)

   setFormData({
    event: '',
    name: '',
    email: '',
    mobileNumber: '', 
   })
  

  //  setTimeout(()=>{
  //    setS({    
 
  //    // submissionSuccess:false,
    
   
  //  })
  //  },6000)
 

  // Handle form submission here
 console.log("Student Data",response.data);
 //  debugger;
 onClose();  
    } catch (error) {
      console.log("Sending Error",error);
      
    }

  }
  return (
    <>
    <section className='mmt20'>
    <div className="form-popup">
              <form onSubmit={handleSubmit} className="form-container">
                <h6 className={`fs-19 mfs-16 fwb ylw-clr tac pdt10 ${extrapopins.className}`}>Enroll in</h6>
                <label className={`fs-16 ${popins.className}`}>Course Name</label>
                <input type="text" name="event" value={formData.event}
                 onChange={handleChange}readOnly/>
                <label htmlFor="name" className={`fs-19 ${popins.className}`}>Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                
                <label htmlFor="email" className={`fs-19 ${popins.className}`}>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                
                <label htmlFor="phone" className={`fs-19 ${popins.className}`}>Phone</label>
                <input type="text" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
                
                <button type="submit" className={`btn cw mt10 ${popins.className}`}>Submit</button>
                {/* <button type="button" className="btn cancel" onClick={onClose}>Cancel</button> */}
              </form>
            </div>
    </section>

    <style jsx>{`
        // /* Existing styles */
        // .form-popup {
        //   position: fixed;
        //   top: 8%;
        //   background-color: white;
        //   padding: 20px;
        //   border: 1px solid #888;
        //   box-shadow: 0 5px 10px rgba(0,0,0,0.3);
        //   z-index: 9;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 350px;


        // }
             /* Existing styles */
        .form-popup {
\
          background-color: white;
          padding: 20px;
          border: 1px solid #888;
          box-shadow: 0 5px 10px rgba(0,0,0,0.3);
          z-index: 9;
          width: 350px;
        }

        .form-container {
          max-width: 300px;
          padding: 10px;
          
        }

        .form-container input[type="text"], 
        .form-container input[type="email"] {
          width: 100%;
          padding: 10px;
          margin: 5px 0 10px 0;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .form-container .btn {
          background-color: #ffa900;
          color: white;
          border: none;
          cursor: pointer;
          width: 100%;
          margin-bottom:10px;
        }

        .form-container .btn.cancel {
          background-color: #cc0000;
        }

        .form-container .btn:hover, .form-container .cancel:hover {
          opacity: 0.8;
        }
          @media only screen and (max-width: 500px){
                 .form-popup {
\
          background-color: white;
          padding: 20px;
          border: 1px solid #888;
          box-shadow: 0 5px 10px rgba(0,0,0,0.3);
          z-index: 9;
          width: auto;
        }
            .form-container {
          max-width: 200px;
          padding: 10px;
          
        }
          }
    `}
</style>
    
    </>
  )
}
