import Layout from "../component/layout";
import MainSection from "../component/Home/Main-Section";
import AchiveBox from "../component/Home/Achive-Box";
import Template from "../component/Home/Template";
import Rectancle from "../component/Home/rect-box";
import TestimonialSlide from "../component/Testimonial-Slide";
import CourseListed from "../component/courselisted";
import NextSeoModule from "@/component/next-seo";
import BlogCards from "@/component/blog/blog-cards";
import { Poppins } from 'next/font/google'
import Head from "next/head";
import EventsCards from "@/component/events/events-cards";
import EventForm from "@/component/events/event-form";
import NewCourseCard from "@/component/new-course-card";
import TrendingCourses from "@/component/coursetemplate/trending-courses";
import EnquiryBtn from "@/component/enquiry-btn";


const popins = Poppins({weight:"500",subsets:['latin']})
export default function Home() {
  const MobCourseData =[
    {
      img:"/course-img/mob-devc-course-img/devlopment-img.webp",
      CourseName:"Development",
      TotalCourses:"34"
    },
    {
      img:"/course-img/mob-devc-course-img/testing-img.webp",
      CourseName:"Testing",
      TotalCourses:"10 Courses"
    },
    {
      img:"/course-img/mob-devc-course-img/cloud-img.webp",
      CourseName:"Cloud",
      TotalCourses:"10 Courses"
    },
    {
      img:"/course-img/mob-devc-course-img/erp-img.webp",
      CourseName:"Erp",
      TotalCourses:"24 Courses"
    },
    {
      img:"/course-img/mob-devc-course-img/program-img.webp",
      CourseName:"Programming",
      TotalCourses:"30 Courses"
    },

  ]


const TrendingCoursesList = [
  { id: "1", path: "aws", courseName: "AWS", courseHours: "40",rating:"4.9", courseEnrolled: "850", width: "51", height: "30", mwidth: "51", mheight: "30", category: "Cloud Computing" },
  { id: "2", path: "azure", courseName: "AZURE", courseHours: "40",rating:"4.1", courseEnrolled: "550", width: "30", height: "30", mwidth: "30", mheight: "30", category: "Cloud Computing" },
  { id: "3", path: "devops", courseName: "DEVOPS", courseHours: "40",rating:"4.8", courseEnrolled: "450", width: "62", height: "30", mwidth: "62", mheight: "30", category: "Devops" },
  { id: "4", path: "azure-data-engineering", courseName: "AZURE DATA ENGINEERING",rating:"4.7", courseHours: "40", courseEnrolled: "300", width: "30", height: "30", mwidth: "30", mheight: "30", category: "Data Engineering" },
  { id: "5", path: "powerapps", courseName: "POWER APPS", courseHours: "45",rating:"4.4", courseEnrolled: "450", width: "37", height: "37", mwidth: "37", mheight: "37", category: "Low Code" },
  { id: "6", path: "flutter", courseName: "FLUTTER", courseHours: "45",rating:"4.0", courseEnrolled: "750", width: "28", height: "35", mwidth: "20", mheight: "25", category: "Development" },

];
const BlogCardsList =[

  {
    id:"1",
    courseName:"Azure Certification",
    cnt:"Passing one or more tests that test your knowledge of Azure services ...",
    bg_img:"/course-img/bg-img/azure.webp",
    category:"Programming",
    path:"/blog/azure-certification",
    date:"15 MARCH 2024"
  },
  {
    id:"2",
    courseName:"AWS Certfication",
    cnt:"AWS certification is a hot topic among IT professionals today. As technology...",
    bg_img:"/course-img/bg-img/aws.webp",
    category:"Programming",
    path:"/blog/aws-certification",
    date:"14 FEB 2024"
  },
  {
    id:"3",
    courseName:"DevOps Tools",
    cnt:"DevOps tools refer to a set of software tools that facilitate and automate . . .",
    bg_img:"/course-img/bg-img/devops.webp",
    category:"Programming",
    path:"/blog/devops-tools",
    date:"04 JAN 2024"
  },


]


  return (
<>
<Head>
        <meta name="robots" content="index,follow" />
      </Head>
          <NextSeoModule
            title="Best Software Training Institute in chennai - learnsoft.org"
            description="learnsoft.org, the leading software training institute in Chennai, provides industry-oriented courses in DevOps, cloud computing, Salesforce, programming, data engineering, web development, software testing and more with job placement opportunities."
            url=""
            img="logob.webp"
            keyword="Software training institutes in Chennai, Best software training courses in Chennai, IT training institutes in Chennai, Online software training in Chennai,Best software training institutes in Chennai, Top software training centers in Chennai, Software certification in Chennai, Job-oriented software training in Chennai, Best software training for beginners in Chennai, Software training with placement in Chennai."
          />
<Layout navbarBackgroundColor="#281E1E">
  <MainSection />

  <AchiveBox/>
     <div className="mt48">
   <TrendingCourses CourseListdata={TrendingCoursesList}/>
   </div>
  {/* style={{paddingLeft: '0px !important'}} */}
  {/* <div className="container">
<CourseListed CourseListdata={CourseListdata}  hidePagination={true} hideSearchBar={true} pdt="pdt80 mpdt30"/>
</div> */}
{/* <EventsCards/> */}
  <Template/>
  <Rectancle/>
  <TestimonialSlide />
  <h4 className={`container fs-33 mfs-20 pdt20 mpdt10 mtac ylw-clr ${popins.className}`}>New this week</h4>
  <BlogCards BlogCardsList={BlogCardsList}  hideTitle={true}/>
   
</Layout>
</>
  );
}
